import React from 'react';
import './HowToPlay.css';
import HowToPlayLinks from "./HowToPlayLinks";
import {NavLink} from "react-router-dom";

function HowToPlay({ loggedIn, playerName, onLogout }) {
    return (
        <div className="main-container">
            <div className="main-content">
                <div className="main-header">
                    <h2>How to Play</h2>
                </div>
                <div className="main-description">
                    <p>Use your arrow keys to move the tiles. When two tiles with the same number touch, they merge into one!</p>
                </div>
                <div className="main-time">
                    <p>Do you still have free time after playing 2048?</p>
                    <p>If <strong>NO</strong>, <NavLink to="EndOfInternet">click here</NavLink> or play <a href="https://www.goodoldtetris.com" target="_blank">Tetris Online</a></p>
                    <p>If <strong>YES</strong>, why don't you check the footer?🤪</p>

                    <HowToPlayLinks/>
                </div>
                <div className="main-game-info">
                    <p>
                        <strong>2048</strong> is an easy and fun puzzle game. Even if you don't love numbers you will love
                        this game.
                        It is played on a 4x4 grid using the arrows or W, A, S, D keys alternatively.
                        Every time you press a key - all tiles slide. Tiles with the same value that bump into
                        one-another are
                        merged.
                        Although there might be an optimal strategy to play, there is always some level of chance.
                        If you beat the game and would like to master it, try to finish with a smaller score. That would
                        mean
                        that you finished with less moves.
                    </p>
                    <p>
                        This game is mobile compatible and you can play it on any device - iPhone, iPad or any other smartphone.
                    </p>
                </div>
                <div className="main-resources">
                    <p>

                        Common <NavLink to="QandA">Q/A</NavLink> about 2048.
                        Learn all about 2048's history on <a href="https://en.wikipedia.org/wiki/2048_(video_game)" target="_blank">wikipedia</a>.
                    </p>
                </div>
            </div>
            {loggedIn &&
                <div className="main-user-info">
                    <p>Logged in as: {playerName}</p>
                    <button onClick={onLogout}>Logout</button>
                </div>
            }
        </div>
    );
}

export default HowToPlay;