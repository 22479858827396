
import HandleGame from "./HandleGame";

function TvrdonFieldReact4(props) {
    return (
        <div style={{ marginTop: '20px' }}>
            <HandleGame {...props} size={4} />
        </div>
    )
}

export default TvrdonFieldReact4;