import React from 'react';
import './Videos.css';
import HowToPlayLinks from "./HowToPlayLinks";

function Videos() {
    const videoData = [
        { id: "2aED90sMZII", title: "3 Simple Tricks to Win 2048 Tile Puzzle Game" },
        { id: "6NtmUZUYOzw", title: "2048 Strategy Guide" },
        { id: "ayqhX9UA6FY", title: "Win 2048 Game: Tips & Tricks / Demonstration" },
        { id: "hiSENn6vzVE", title: "2048 Game - A Successful Gameplay" },
        { id: "ByRp-ZZUWWo", title: "How to beat the 2048 game with a few tactics" }
    ];

    const generateEmbedUrl = (videoId) => `https://www.youtube.com/embed/${videoId}`;

    return (
        <div className="videos">
            <div className="videos-container">
                <h2>2048 Game Videos</h2>
                <p>All Videos are in English</p>
                <ul>
                    {videoData.map((video, index) => (
                        <li key={index}>
                            <div className="video-wrapper">
                                <h3>{video.title}</h3>
                                <iframe
                                    title={`Video ${index + 1}`}
                                    width="100%"
                                    height="100%"
                                    src={generateEmbedUrl(video.id)}
                                    frameBorder="0"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </li>
                    ))}
                </ul>
                <HowToPlayLinks/>
            </div>
        </div>

    );
}

export default Videos;