import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import "./LoginForm.css";
import {addUser, getUserPassword} from "./_api/login_service";
import {Link, useNavigate} from "react-router-dom";

function RegistrationForm() {
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: 'onChange'
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const navigate = useNavigate();

    const onSubmit = data => {
        getUserPassword(data.login).then(response => {
            if(response.data === "") {
                if(data.passwd === data.confirmPassword) addUser(data.login, data.passwd, data.idnum).then(res => {
                    setErrorMessage("");
                    setSuccessMessage("Registration successful!");
                    setTimeout(() => {
                        navigate("/");
                    }, 2000);
                });
                else {
                    setErrorMessage("Passwords do not match");
                    setTimeout(() => {
                        setErrorMessage("");
                    }, 5000);
                }
            }
            else {
                setErrorMessage("User already exists");
                setTimeout(() => {
                    setErrorMessage("");
                }, 5000);
            }
        });
    }

    return (
        <div className="form-container">
            <h1>Register to Gamestudio</h1>
            <div className="form-wrapper">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="form-group">
                        <Form.Label className="form-label">Login:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your login"
                            {...register("login", {
                                minLength: {value: 5, message: "Minimum is 5 characters."},
                                required: "Login is required",

                            })}
                            className="form-control"
                        />
                        <Form.Text style={{color: 'red', float: 'right'}}>
                            {errors.login && errors.login.message}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="form-group">
                        <Form.Label className="form-label">Password:</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter your password"
                            {...register("passwd", {
                                minLength: {value: 8, message: "Minimum is 8 characters."},
                                required: "Password is required",
                            })}
                            className="form-control"
                        />
                        <Form.Text style={{color: 'red', float: 'right'}}>
                            {errors.passwd && errors.passwd.message}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="form-group">
                        <Form.Label className="form-label">Confirm Password:</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm your password"
                            {...register("confirmPassword", {
                                minLength: {value: 8, message: "Minimum is 8 characters."},
                                required: "Confirm password is required"
                            })}
                            className="form-control"
                        />
                        <Form.Text style={{color: 'red', float: 'right'}}>
                            {errors.confirmPassword && errors.confirmPassword.message}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="form-group">
                        <Form.Label className="form-label">ID Number:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your ID number"
                            {...register("idnum", {
                                minLength: {value: 8, message: "Minimum is 8 characters."},
                                maxLength: {value: 10, message: "Maximum is 10 characters."},
                                required: "ID number is required"
                            })}
                            className="form-control"
                        />
                        <Form.Text style={{color: 'red', float: 'right'}}>
                            {errors.idnum && errors.idnum.message}
                        </Form.Text>
                    </Form.Group>

                    <Button type="submit" disabled={!isValid} className="submit-btn">Register</Button>
                    <Form.Text style={{color: 'red', float: 'right'}}>
                        {errorMessage}
                    </Form.Text>
                    <Form.Text style={{color: 'green', float: 'right'}}>
                        {successMessage}
                    </Form.Text>
                    <div className="registration-link">
                        <p><Link to="/">Back to Login</Link></p>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default RegistrationForm;