import React from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form"
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import "./CommentForm.css";

function CommentForm ({onSendComment}) {
    const { register, handleSubmit, formState: { errors, isValid }} = useForm({
        mode: 'onChange'
    });
    const navigate = useNavigate();
    const onSubmit = data => {
        onSendComment(data.comment);
    }
    return (
        <div className="comment-form-container">
            <div className="comment-form-wrapper">
                <h2 className="comment-form-label">Add your Comment</h2>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                        <input
                            className="comment-form-input"
                            type="text"
                            {...register("comment", {
                                minLength: {value: 3, message: "Minimum is 3 characters." },
                                maxLength: {value: 150, message: "Maximum is 150 characters." },
                                required: {value: true, message: "Comment message is required." },
                            })}
                            placeholder="Enter comment message here."
                        />
                        <Form.Text className="comment-form-error">
                            {errors.comment?.message}
                        </Form.Text>
                    </Form.Group>
                    <Button className="comment-form-submit" type='submit' disabled={!isValid} onClick={() => navigate(-1)}>Send</Button>
                </Form>
            </div>
        </div>
    )
}

export default CommentForm;