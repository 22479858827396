import React from 'react';
import './Quotes.css';
import HowToPlayLinks from "./HowToPlayLinks";

function Quotes() {
    return (
        <div className="quotes-container">
            <h2>2048 Quotes</h2>
            <div className="quote">
                <p>
                    “Help! I can't stop playing 2048.”
                </p>
            </div>
            <div className="quote">
                <p>
                    “2048 Game is the most addictive game I have ever played!”
                </p>
            </div>
            <div className="quote">
                <p>
                    “Don't play 2048 if you have homework to do.”
                </p>
            </div>
            <div className="quote">
                <p>
                    “Sorry for being late, bro, I just couldn't stop playing the game you showed me yesterday.”
                </p>
            </div>
            <div className="quote">
                <p>
                    “I've been playing this all day today. I basically created my own demise.”
                </p>
            </div>
            <div className="quote">
                <p>
                    “Help! I can't stop playing 2048.”
                </p>
            </div>
            <div className="quote">
                <p>
                    “Holy *** I just lost like 45 minutes to this.”
                </p>
            </div>
            <div className="quote">
                <p>
                    “Evil evil game.”
                </p>
            </div>
            <div className="quote">
                <p>“I just spent a few hours on this. I'm in love-hate relationships with the game now.” “Help! I can't stop playing 2048.”
                </p>
            </div>
            <div className="quote">
                <p>
                    “There was light outside my window when I first began playing this, and now it's pitch dark.”
                </p>
            </div>
            <HowToPlayLinks/>
        </div>
    );
}

export default Quotes;