import gsAxios from "./index";
import {formatDate} from "./utils";

//get api/score/1024
//post api/score - score

export const fetchScores = game => gsAxios.get('/api/score/' + game);
export const addScore = (game, player, points) => gsAxios.post('/api/score', {
    game, player, points, playedOn: formatDate(new Date()),
}).then(() => {
    return fetchScores(game);
});