import React, { useEffect, useState } from "react";
import { addComment, fetchComments } from "./components/services-react/_api/comment_service";
import Comments from "./components/services-react/Comments";
import Scores from "./components/services-react/Scores";
import CommentForm from "./components/services-react/CommentForm";
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginForm from "./components/user-react/LoginForm";
import { addScore, fetchScores } from "./components/services-react/_api/score_service";
import fieldService from "./components/field-react/_api/gameFieldService";
import Menu from "./components/Menu";
import { Route, Routes, useNavigate } from "react-router-dom";
import RegistrationForm from "./components/user-react/RegistrationForm";
import './App.css';
import { fetchRating } from "./components/services-react/_api/rating_service";
import HowToPlay from "./components/index-react/HowToPlay";
import TipsAndTricks from "./components/index-react/TipsAndTricks";
import Quotes from "./components/index-react/Quotes";
import Variations from "./components/index-react/Variations";
import Videos from "./components/index-react/Videos";
import QandA from "./components/index-react/QandA";
import EndOfInternet from "./components/index-react/EndOfInternet";
import TvrdonFieldReact6 from "./components/field-react/TvrdonFieldReact6";
import TvrdonFieldReact5 from "./components/field-react/TvrdonFieldReact5";
import TvrdonFieldReact4 from "./components/field-react/TvrdonFieldReact4";

const gameList = [
    { title: "2048 4x4", path: "field-react1" },
    { title: "2048 5x5", path: "field-react2" },
    { title: "2048 6x6", path: "field-react3" },
];

function App() {
    const selectedGame = '2048';
    const [comments, setComments] = useState([]);
    const [scores, setScores] = useState([]);
    const [rating, setRating] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);
    const [playerName, setPlayerName] = useState('');
    const navigate = useNavigate();
    const [time1, setTime1] = useState(0);
    const [time2, setTime2] = useState(0);
    const [time3, setTime3] = useState(0);

    const fetchData = () => {
        fetchComments(selectedGame)
            .then(res => {
                setComments(res.data);
            })
            .catch(error => {
                console.error('Error fetching comments:', error);
            });
        fetchScores(selectedGame)
            .then(res => {
                setScores(res.data);
            })
            .catch(error => {
                console.error('Error fetching score:', error);
            });
        fetchRating(selectedGame)
            .then(res => {
                setRating(res.data);
            })
            .catch(error => {
                console.error('Error fetching rating:', error);
            });
    }

    useEffect(() => {
        const storedPlayerName = localStorage.getItem('playerName');
        if (storedPlayerName) {
            setPlayerName(storedPlayerName);
            setLoggedIn(true);
        }
        fetchData();
    }, []);

    const setTime = () => {
        setTime1(0);
        setTime2(0);
        setTime3(0);
    }

    const handleLoginSuccess = (username) => {
        setLoggedIn(true);
        setPlayerName(username);
        localStorage.setItem('playerName', username); // Save to local storage
        fieldService.newGame(4);
        navigate('/');
        setTime();
    };

    const handleLogout = () => {
        setLoggedIn(false);
        setPlayerName('');
        localStorage.removeItem('playerName'); // Remove from local storage
        fieldService.newGame(4);
        setTime();
    };

    const handleSendComment = (comment) => {
        addComment(selectedGame, playerName, comment).then(res => {
            fetchData();
        });
    };

    const handleAddScore = (playerName, score) => {
        addScore("1024", playerName, score).then(() => {
            fetchData();
        })
            .catch(error => {
                console.error('Error adding score:', error);
            });
    };

    return (
        <div className="App">
            <Menu gameList={gameList} />
            <Routes>
                <Route path="/" element={loggedIn ? <HowToPlay loggedIn={loggedIn} playerName={playerName} onLogout={handleLogout} /> : <LoginForm onLoginSuccess={handleLoginSuccess} />} />

                <Route path="/registrationForm" element={<RegistrationForm onLoginSuccess={handleLoginSuccess} />} />

                <Route path="/scores" element={<Scores scores={scores} />} />

                <Route path="/comments" element={<Comments comments={comments} loggedIn={loggedIn} />} />
                <Route path="/comments/add" element={<CommentForm onSendComment={handleSendComment} />} />

                <Route path="/TipsAndTricks" element={<TipsAndTricks />} />
                <Route path="/Quotes" element={<Quotes />} />
                <Route path="/Variations" element={<Variations />} />
                <Route path="/Videos" element={<Videos />} />
                <Route path="/QandA" element={<QandA />} />
                <Route path="/EndOfInternet" element={<EndOfInternet />} />

                <Route path="/game/field-react1" element={<TvrdonFieldReact4 playerName={playerName} rating={rating} onAddScore={handleAddScore} time={time1} updateTime={setTime1} />} />
                <Route path="/game/field-react2" element={<TvrdonFieldReact5 playerName={playerName} rating={rating} onAddScore={handleAddScore} time={time2} updateTime={setTime2} />} />
                <Route path="/game/field-react3" element={<TvrdonFieldReact6 playerName={playerName} rating={rating} onAddScore={handleAddScore} time={time3} updateTime={setTime3} />} />
            </Routes>
        </div>
    );
}

export default App;