import gsAxios from "./index";
import {formatDate} from "./utils";

//get api/rating/1024
//post api/rating - rating

export const fetchRating = game => gsAxios.get('/api/rating/' + game);
export const fetchRatingPlayer = (game, player) => gsAxios.get('/api/rating/' + game + '/' + player);
export const addRating = (game, player, rating) => gsAxios.post('/api/rating/', {
    game, player, rating, ratedOn: formatDate(new Date()),
});