import React from 'react';
import './QandA.css';
import {NavLink} from "react-router-dom";
import HowToPlayLinks from "./HowToPlayLinks";

function QandA() {
    return (
        <div className="qna-container">
            <h2>Questions and Answers about 2048</h2>
            <div className="question">
                👩‍🎓👇❓
                <p>Can you get to 2048?</p>
                <p>- Yes. Many players are able to get to 2048 after a few games or days. Rarely in the first game. Once
                    you get the idea of the game, you can try the corner strategy. You can read more about it on
                    our <NavLink to="/TipsAndTricks">tips and tricks</NavLink> page.</p>
            </div>
            <div className="question">
                <p>How can I play 2048 on my computer?</p>
                <p>- You can play 2048 on any computer by simply using your browser. Open 2048game.com, then click on
                    "new game" and use the arrows to move the tiles. You will find further instructions just below the
                    game itself. It is free, and you don't have to download any apps from the internet.</p>
            </div>
            <div className="question">
                <p>Is 2048 a math game?</p>
                <p>- 2048 is a popular single-player puzzle game for web and mobile. Many people think that it is a math
                    game as it is based on numbers. However, it isn't. You will love it even if you are not good at
                    maths. It's more like logic and intuition game.</p>
            </div>
            <div className="question">
                <p>What is the highest score in 2048?</p>
                <p>- TLDR: 131,972. Here it is an example of the board at that point. You will need some luck, though.
                    To get that very rare score, you need to spawn a 4 tile as your last tile. In case that you are not
                    that lucky, the theoretical highest score is 2 on the power of 16 or 65,536.</p>
            </div>
            <div className="question">
                <p>How does scoring works?</p>
                <p>- Merging two lower tier blocks together will give you the score of the higher one (score of +8
                    gained from merging two 4's). For any specific tile score, you have to add up all the scores from
                    the lower tiers.<br/> Here they are some examples:
                    <br/>
                    Creating a 1 or 2 tile = +0pts<br/>
                    Creating a 4 tile = 4 = +4pts<br/>
                    8 = 8 + 2x4 = +16pts<br/>
                    16 = 16 + 2x8 + 4x4 = +48pts<br/>
                    32 = 32 + 2x16 + 4x8 + 8x4 = +128pts<br/>
                    etc</p>
            </div>
            <div className="question">
                <p>Why is the game 2048 called 2048?</p>
                <p>- Reaching the 2048 tile is your goal. You start from 1 and 2. By merging them you double the next tile up to 2048. Here they are all the possible tiles: 🚀 1 => 2 => 4 => 8 => 16 => 32 => 64 => 128 => 256 => 512 => 1024 => 2048 ✨</p>
            </div>
            <HowToPlayLinks/>
        </div>
    );
}

export default QandA;