import "./Tile.css";

function Tile({ tile, size }) {
    const zeroValue = " ";
    const tileSize = size === 4 ? "large" : size === 5 ? "medium" : "small";

    return (
        <td className={`tile value-${tile.value} ${tileSize}`}>
            <span>{tile.value > 0 ? tile.value : zeroValue}</span>
        </td>
    );
}

export default Tile;