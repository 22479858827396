import Tile from "./Tile";
import "./Field.css";

function Field({ gameboard, size }) {
    return (
        <table className="field">
            <tbody>
            {gameboard.map((row, rowIndex) => (
                <tr key={`row-${rowIndex}`}>
                    {row.map((tile, colIndex) => (
                        <Tile key={`tile-${rowIndex}-${colIndex}`} tile={tile} size={size} />
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default Field;