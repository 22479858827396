import React from 'react';
import './Variations.css';
import HowToPlayLinks from "./HowToPlayLinks";

function Variations() {
    return (
        <div className="variations-container">
            <h2>2048 Game Variations</h2>
            <p>There are a number of variations of the 2048 game. You can find them listed below. This is the most optimized and fastest loading classic version.</p>
            <ul>
                <li>Classic Version</li>
                <li>Flappy 2048</li>
                <li>2048 Animated Edition</li>
                <li>2048 Tetris Mode</li>
                <li>2048 Doggy Mode</li>
                <li>2048 Reverse Mode</li>
                <li>2048 Pokemon Mode</li>
                <li>8402 (Beat The Computer)</li>
                <li>Numberwang (Crazy!)</li>
                <li>LHC (for physicist)</li>
                <li>2048 Colors Mode</li>
                <li>Speed Mode</li>
                <li>2048 Artificial Intelligence</li>
                <li>5x5 Board</li>
                <li>6x6 Board</li>
                <li>8x8 Board</li>
            </ul>
            <HowToPlayLinks/>
        </div>
    );
}

export default Variations;