import React, { useState} from 'react';
import { Rating } from 'react-simple-star-rating';
import { addRating } from "./_api/rating_service";

export function MyRating({ selectedGame, playerName, onRatingChange, playerRating }) {
    const [rating, setRating] = useState(playerRating);

    const handleRating = (rate) => {
        setRating(rate);
        addRating(selectedGame, playerName, rate)
            .then(response => {
                onRatingChange(rate);
            })
    };

    return (
        <Rating initialValue={playerRating} onClick={handleRating} ratingValue={rating} />
    );
}