import React, { useEffect, useState, useRef } from "react";
import fieldService from "./_api/gameFieldService";
import Field from "./_api/Field";
import "./TvrdonFieldReact.css";
import Button from "react-bootstrap/Button";
import { MyRating } from "../services-react/MyRating";
import { NavLink } from "react-router-dom";
import { fetchRating, fetchRatingPlayer } from "../services-react/_api/rating_service";
import logo from './giphy.gif';

function HandleGame({ playerName, onAddScore, time, updateTime, size }) {
    const [field, setField] = useState(null);
    const [rating, setRating] = useState(0);
    const [playerRating, setPlayerRating] = useState(0);
    const [gameLost, setGameLost] = useState(false);
    const [gameWon, setGameWon] = useState(false);
    const scoreAddedRef = useRef(false);
    const lastMoveTimeRef = useRef(null);
    const fieldRef = useRef(null);
    const timerRef = useRef(null);
    const lastTouchRef = useRef(null);

    useEffect(() => {
        fieldService.newGame(size).then(res => {
            setField(res.data);
            setGameLost(false);
            setGameWon(false);
        });
        resetTime()
    }, [size]);

    useEffect(() => {
        fetchRatingData();
        fetchRatingPlayerData();
    }, []);

    const resetTime = () => {
        updateTime(0);
    };

    const updateTimer = () => {
        if (fieldRef.current?.state !== "END") updateTime(prevTime => prevTime + 1);
        else clearInterval(timerRef.current);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            updateTimer();
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        fieldRef.current = field;
    }, [field]);

    const handleKeyDown = (event) => {
        const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "w", "s", "a", "d"];
        if (arrowKeys.includes(event.key)) {
            event.preventDefault();
            const now = Date.now();
            if (!lastMoveTimeRef.current || now - lastMoveTimeRef.current > 100) {
                lastMoveTimeRef.current = now;
                let direction;
                switch (event.key) {
                    case "ArrowUp":
                        direction = "UP";
                        break;
                    case "ArrowDown":
                        direction = "DOWN";
                        break;
                    case "ArrowLeft":
                        direction = "LEFT";
                        break;
                    case "ArrowRight":
                        direction = "RIGHT";
                        break;
                    case "w":
                        direction = "UP";
                        break;
                    case "s":
                        direction = "DOWN";
                        break;
                    case "a":
                        direction = "LEFT";
                        break;
                    case "d":
                        direction = "RIGHT";
                        break;
                    default:
                        break;
                }
                handleMove(direction);
            }
        }
    };

    const handleTouchStart = (event) => {
        event.preventDefault();
        const touch = event.touches[0];
        lastTouchRef.current = {
            x: touch.clientX,
            y: touch.clientY
        };
    };

    const handleTouchMove = (event) => {
        event.preventDefault();
        const touch = event.touches[0];
        const deltaX = touch.clientX - lastTouchRef.current.x;
        const deltaY = touch.clientY - lastTouchRef.current.y;
        let direction;
        if (Math.abs(deltaX) > Math.abs(deltaY)) direction = deltaX > 0 ? "RIGHT" : "LEFT";
        else direction = deltaY > 0 ? "DOWN" : "UP";
        handleMove(direction);
    };

    const handleTouchEnd = (event) => {
        event.preventDefault();
        lastTouchRef.current = null;
    };

    const handleMove = (direction) => {
        fieldService.move(direction).then(res => {
            setField(res.data);
            if (res.data.state === "END" || res.data.state === "SOLVED") {
                if (!scoreAddedRef.current && playerName !== "") {
                    onAddScore(playerName, res.data.score);
                    scoreAddedRef.current = true;
                }
                if (res.data.state === "END") setGameLost(true);
                else setGameWon(true);
                timerRef.current = setTimeout(() => {
                    setGameLost(false);
                    setGameWon(false);
                }, 3000);
            }
        });
    };

    const handleNewGame = () => {
        fieldService.newGame(size).then(res => {
            setField(res.data);
            scoreAddedRef.current = false;
            resetTime();
            setGameLost(false);
            setGameWon(false);
            clearTimeout(timerRef.current);
        });
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("touchstart", handleTouchStart, false);
        window.addEventListener("touchmove", handleTouchMove, false);
        window.addEventListener("touchend", handleTouchEnd, false);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("touchstart", handleTouchStart);
            window.removeEventListener("touchmove", handleTouchMove);
            window.removeEventListener("touchend", handleTouchEnd);
        };
    }, []);

    const fetchRatingData = () => {
        fetchRating("1024")
            .then(res => {
                setRating(res.data);
            })
            .catch(error => {
                console.error('Error fetching rating:', error);
            });
    };

    const fetchRatingPlayerData = () => {
        fetchRatingPlayer("1024", playerName).then(res => {
            if (!(playerName === "")) setPlayerRating(res.data);
        }).catch(error => {
            console.error('Error fetching player rating:', error);
        });
    };

    const handleRatingChange = (newRating) => {
        setRating(newRating);
        fetchRatingData();
        fetchRatingPlayerData();
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className="game-container">
            {gameLost && (
                <div className="game-over-container">
                    <img src={logo} alt="loading..."/>
                </div>
            )}
            {gameWon && (
                <div className="game-over-container">
                    <h2 style={{ animation: 'moveText 1s ease forwards' }}>Winner</h2>
                    <span style={{ animation: 'moveEmoji 1s ease-in-out forwards, rotateEmoji 1s linear infinite' }}>🥳</span>
                </div>
            )}
            <table className="game-table">
                <tbody>
                <tr>
                    <td style={{ color: "black" }}><h3>Score: {field?.score}</h3></td>
                    <td style={{ color: "black" }}><h3>State: {field?.state}</h3></td>
                </tr>
                <tr>
                    <td style={{ color: "black" }}><h3>Time: {formatTime(time)}</h3></td>
                    <td ><Button variant="info" onClick={handleNewGame}>New Game</Button></td>
                </tr>
                </tbody>
            </table>
            <div className="game">
                {field && <Field gameboard={field.gameboard} size={size} />}
            </div>
            {playerName !== "" ?
                <>
                    <h3>Player's Rating:</h3>
                    <MyRating selectedGame="1024" playerName={playerName} onRatingChange={handleRatingChange} playerRating={playerRating} />
                </>
                :
                <NavLink to="/" className="login-text">If you want to rate the game, please login</NavLink>}
            <h3>Average Game Rating: {rating}</h3>
        </div>
    )
}

export default HandleGame;