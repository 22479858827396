import React from 'react';
import './EndOfInternet.css'; // Don't forget to create the corresponding CSS file

function EndOfInternet() {
    return (
        <div className="end-of-internet-container">
            <h2>Congratulations!</h2>
            <p>You have finally reached the end of the internet!</p>
            <p>There's nothing more to see, no more links to visit.</p>
            <p>You've done it all.</p>
            <p>This is the very last page on the very last server at the very far end of the internet.</p>
            <p>You should now turn off your computer and go do something useful with the rest of your life.</p>
            <p><strong>Suggestions:</strong></p>
            <ul>
                <li>Read a book</li>
                <li>Do some public service</li>
                <li>Personally interact with your neighbors that you've probably only met online</li>
                <li>Plant a tree</li>
                <li>Introduce yourself to those other people who live at your house (your family)</li>
            </ul>
            <p>* Please don't forget to turn off the lights on your way out.</p>
            <p>In order to save time, we will now start downloading the internet to your local drive.</p>
        </div>
    );
}

export default EndOfInternet;