import gsAxios from "../../services-react/_api";

const FIELD_URL = "/api/1024/field"; // Updated URL
const NEW_GAME_URL = "/api/1024/field/newGame"; // Updated URL
const MOVE_URL = "/api/1024/field/move"; // Updated URL

const fetchField = () => gsAxios.get(FIELD_URL);
const newGame = size => gsAxios.get(`${NEW_GAME_URL}?size=${size}`);
const move = param => gsAxios.get(`${MOVE_URL}?param=${param}`);

const fieldService = { fetchField, newGame, move };
export default fieldService;