import HandleGame from "./HandleGame";

function TvrdonFieldReact6(props) {
    return (
        <div style={{ marginTop: '60px' }}>
            <HandleGame {...props} size={6} />
        </div>
    );
}

export default TvrdonFieldReact6;