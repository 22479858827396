import React from "react";
import { Table } from "react-bootstrap";
import "./Scores.css";

function Scores({ scores }) {
    return (
        <div className="scores-container">
            <div className="scores-wrapper">
                <h1 className="scores-header">Scores</h1>
                <Table responsive striped bordered hover className="scores-table">
                    <thead>
                    <tr>
                        <th>Player</th>
                        <th>Score</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {scores.map((score, index) => (
                        <tr key={index}>
                            <td>{score.player}</td>
                            <td>{score.points}</td>
                            <td>{new Date(score.playedOn).toLocaleString()}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default Scores;