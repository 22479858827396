import React from 'react';
import HowToPlayLinks from "./HowToPlayLinks";
import './TipsAndTricks.css'

function TipsAndTricks() {
    return (
        <div className="tips-container">
            <div className="tips-section">
                <h2>2048 Game Tips & Tricks</h2>
                <h5>Take It Slow</h5>
                2048 is so easy to play, you might get into the habit of tearing through games without even really paying attention to what you're doing. That's not a good idea. Take your time - there's no penalty for thinking your moves through after all. Also, try to predict where new blocks might come in and how you could deploy them. Something like playing chess.
                <h5>Work The Corners</h5>
                In order to win the game you should have a specific strategy. If you do not have one, you will most probably not reach further than 512. So, a good strategy is to always keep your highest value tile at one of the corners. Just choose one corner and direct all your tiles there. It is not as easy as it sounds, but you can master it quickly. In order to stop the highest value tile to leave "the king's place", try to pack tiles around it. Preferably, they should be the other biggest tiles. Master this simple strategy and you will soon reach 1024 and even win the 2048 Game!
                <h5>How to make the highest valued tile in the right bottom corner and not to move it?</h5>
                Once you have placed your highest tile value is in the right bottom corner, do not move it. To make sure it is not moved, you have to make the last row always filled by pushing the down arrow so that the use of LEFT and Right Arrow would not move the highest valued tile. Basically, this is the most important cheat for 2048 Game - keep the highest tile in the corner and do not move it.
            </div>
            <HowToPlayLinks/>
        </div>
    );
}

export default TipsAndTricks;