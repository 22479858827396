import React from "react";
import { Table } from "react-bootstrap";
import "./Comments.css";
import { useNavigate } from "react-router-dom";

function Comments({ comments, loggedIn }) {
    const navigate = useNavigate();

    return (
        <div className="comments-container">
            <div className="comments-wrapper">
                <h1 className="comments-header">Comments</h1>
                <Table responsive striped bordered hover className="comments-table">
                    <thead>
                    <tr>
                        <th>Player</th>
                        <th>Comment</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {comments.map((comment, index) => (
                        <tr key={index}>
                            <td>{comment.player}</td>
                            <td>{comment.comment}</td>
                            <td>{new Date(comment.commentedOn).toLocaleString()}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
            {loggedIn && (
                <button className="abutton" onClick={() => navigate("/comments/add")}>Add comment</button>
            )}
        </div>
    );
}

export default Comments;