import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink } from "react-router-dom";

const Menu = ({ gameList }) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => setExpanded(!expanded);
    const handleClose = () => setExpanded(false);

    return (
        <Navbar expanded={expanded} onToggle={handleToggle} collapseOnSelect expand="lg" bg="light" variant="light" style={{ position: "fixed", width: '100%', top: '0', left: '0' }}>
            <Container>
                <Link className="navbar-brand" to="/">Gamestudio - 2048</Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Item>
                            <NavLink className="nav-link" to="/scores" onClick={handleClose}>Scores</NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink className="nav-link" to="/comments" onClick={handleClose}>Comments</NavLink>
                        </Nav.Item>
                        <NavDropdown title="Games" id="nav-dropdown">
                            {gameList.map(game => (
                                <NavDropdown.Item as={NavLink} to={`game/${game.path}`} key={game.path} onClick={handleClose}>
                                    {game.title}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Menu;