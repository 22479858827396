import HandleGame from "./HandleGame";

function TvrdonFieldReact5(props) {
    return (
        <div style={{ marginTop: '45px' }}>
            <HandleGame {...props} size={5} />
        </div>
    );
}

export default TvrdonFieldReact5;