import React from 'react';
import './HowToPlayLinks.css';
import { NavLink } from "react-router-dom";

function HowToPlayLinks() {
    return (
        <div className="how-to-play-links-container">
            <p className="how-to-play-title">Learn more about 2048 Game</p>
            <div className="how-to-play-table-container">
                <div className="how-to-play-column">
                    <NavLink className="how-to-play-nav-link" to="/TipsAndTricks">Tips & Tricks</NavLink>
                    <NavLink className="how-to-play-nav-link" to="/Videos">2048 Videos</NavLink>
                </div>
                <div className="how-to-play-column">
                    <NavLink className="how-to-play-nav-link" to="/Quotes">2048 Quotes</NavLink>
                    <NavLink className="how-to-play-nav-link" to="/Variations">2048 Variations</NavLink>
                </div>
            </div>
        </div>
    );
}

export default HowToPlayLinks;
