import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import {getPasswdApprove} from "./_api/login_service";
import "./LoginForm.css";
import {useState} from "react";
import {Link} from "react-router-dom";

function LoginForm({ onLoginSuccess }) {
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const [errorMessage, setErrorMessage] = useState("");

    const onSubmit =  data => {
        getPasswdApprove(data.login, data.password).then(response => {
            const access = response.data;
            if(access === true) onLoginSuccess(data.login);
            else {
                setErrorMessage("Login unsuccessful. Please check your credentials.");
                setTimeout(() => {
                    setErrorMessage("");
                }, 5000);
            }
        })
    };

    return (
        <div className="form-container">
            <h1>Login to Gamestudio</h1>
            <div className="form-wrapper">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="form-group">
                        <Form.Label className="form-label">Login:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your login"
                            {...register("login", {
                                required: "Login is required"
                            })}
                            className="form-control"
                        />
                        <Form.Text className="text-danger">
                            {errors.login && errors.login.message}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="form-group">
                        <Form.Label className="form-label">Password:</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Enter your password"
                            {...register("password", {
                                required: "Password is required"
                            })}
                            className="form-control"
                        />
                        <Form.Text className="text-danger">
                            {errors.password && errors.password.message}
                        </Form.Text>
                    </Form.Group>

                    <Button type="submit" className="submit-btn">Login</Button>
                    <Form.Text style={{color: 'red', float: 'right'}}>
                        {errorMessage}
                    </Form.Text>
                </Form>
                <div className="registration-link">
                    <p>Don't have an account? <Link to="/registrationForm">Register here</Link></p>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;